import styled, { css } from 'styled-components';
import { activeMessageAtom } from '../../../../atoms/messaging';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../buttons/IconButton';
import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi';
import Timer from './Timer';
import { useAtom, useAtomValue } from 'jotai';
import i18n from '../../../../tools/i18n';
import { objectsStatesAtom } from '../../../../atoms/scene';
import { fadeIn, slideInBottom } from '../../../../style/animations';
import Options from './Options';
import { Message, StandardMessage as StandardMessageType } from '../../../../atoms/content';

const StandardMessage = ({ messageData, onContinue }: MessageProps) => {

	const { t } = useTranslation();
	const NPCSpeaking = useMemo(() => messageData.speaking_character !== -1 ? true : false, [messageData]);
	const [activeMessageId] = useAtom(activeMessageAtom);
	const showStatusTimeout = useRef<NodeJS.Timeout>();
	const sceneObjectStates = useAtomValue(objectsStatesAtom);
	const [previousMessageData, setPreviousMessageData] = useState<typeof messageData>();
	const [timerEnded, setTimerEnded] = useState(false);
	const [showStatus, setShowStatus] = useState<boolean>(true);

	//#region changing data

	// determines what is rendered in the status dependent circle
	const status = useMemo(() => {
		if (messageData.answers.length != 0) {
			return 1;
		}
		return 0;
	}, [messageData]);

	const animating = useMemo(() => {//character is currently animating
		if (!sceneObjectStates[messageData.speaking_character]) return false;
		return sceneObjectStates[messageData.speaking_character].animation != '';
	}, [sceneObjectStates]);

	//#endregion

	//#region use effects

	useEffect(() => {
		setTimerEnded(false); //reset
		// check to make sure status block is rerendered when a new message comes
		if (previousMessageData && previousMessageData.answers && messageData.answers) {
			setShowStatus(false);
			if (showStatusTimeout.current) clearTimeout(showStatusTimeout.current);
			showStatusTimeout.current = setTimeout(() => {
				setShowStatus(true);
			}, 100);
		}
		setPreviousMessageData(messageData);
	}, [messageData]);

	//#endregion

	const onNextMessage = () => {
		onContinue();
	};

	return (
		<Subcontainer style={{ direction: i18n.dir() }}>
			<TopContainer>
				<div>
					{messageData.answers.length != 0 && !animating && <Options
						onContinue={onContinue}
						data={messageData}
						timerEnded={timerEnded}
					/>}
				</div>
			</TopContainer>
			<Container $npcspeaking={NPCSpeaking}>
				<Name $npcspeaking={NPCSpeaking}>
					<div>{NPCSpeaking ? t(`character.${messageData.speaking_character}.name`) : t('game.message.name_label_player')}</div>
				</Name>
				<Text>{t(`message.${activeMessageId}.text`)}</Text>

				{showStatus && !animating &&
					<StatusDependentCircle>
						{status === 0 ?
							<NextButton onClick={onNextMessage}>
								{i18n.dir() === 'ltr' ?
									<BiRightArrowAlt /> :
									<BiLeftArrowAlt />
								}
							</NextButton>
							: status === 1 ?
								<Timer onTimerEnd={() => { setTimerEnded(true); }} />
								:
								<>
								</>
						}
					</StatusDependentCircle>
				}
			</Container>
		</Subcontainer>
	);
};

// styled components
const NextButton = styled(IconButton)`
	animation: ${fadeIn} 1s forwards;
`;

const TopContainer = styled.div`
	height: 0;
	position: relative;

	& > div {
		position: absolute;
		inset-inline: 0;
		inset-block-end: 0;

		width: 100%;
		padding-inline-start: .5em;
		height: fit-content;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;

		gap: 0.25em;

		@media (max-width: ${p => p.theme.responsive.media.sm}){
			padding-inline-end: 0.5em;
			overflow: hidden;
		}
	}
`;


const Subcontainer = styled.div`
	position: relative;
	pointer-events: all;

	opacity: 0;
	translate: 0, 200%;
	animation: ${slideInBottom} 1.5s ease forwards;
	display: flex;
	height: 100%; 
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 1em;

	gap: 1em;

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		font-size: 1.7em;
		padding-bottom: 0;
	}
	
`;

const Container = styled.div<{ $npcspeaking: boolean }>`
	position: relative;
	height: 25%; // make bigger on screen with small width (portrait)

	margin-top: 1em; // extra space for the name label
	
	border-radius: 10em;

	color: ${p => p.theme.colors.neutralLightest};
	background-color: ${p => p.theme.colors.neutralDarkestT};

	flex-shrink: 0;

	// apply a different style for when message is being said by the player.
	${p => !p.$npcspeaking && css`
		color: ${p => p.theme.colors.neutralDarkest};
		background-color: ${p => p.theme.colors.neutralLightestT};
	`}

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		height: 35%;
		border-radius: 0;
	}

	transition: background-color .5s;
	
`;

const Name = styled.div<{ $npcspeaking: boolean }>`
	position: absolute;
	top:0;left:50%;
	border-radius: 2em 2em 0 0;
	translate: -50% -100%;
	z-index: 2;
	height: fit-content;

	margin-inline: auto;
	width: 10em;
	color: ${p => p.theme.colors.neutralLightest};
	background-color: ${p => p.theme.colors.neutralDarkestT};

	// apply a different style for when message is being said by the player.
	${p => !p.$npcspeaking && css`
		color: ${p => p.theme.colors.neutralDarkest};
		background-color: ${p => p.theme.colors.neutralLightestT};
	`}

	transition: background-color .5s;

	& > div {
		translate: 0 30%;
		text-align: center;
		width: 100%;
	}
`;

const Text = styled(ReactMarkdown)`
	margin: auto;
	padding-inline: 7.5em; // r
	text-align: center;
	text-wrap: balance;
	font-weight: bold;

	height: 100%;

	display: flex; align-items: center; justify-content: center;

	& > * {
		margin: 0;
	};

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		margin-bottom: 0;
		padding-inline-start: .5em; // r
		padding-inline-end: 5.5em;
		font-size: 0.95em;
	};

`;



const StatusDependentCircle = styled.div`
	position: absolute;
	width: 5em;
	aspect-ratio: 1/1;

	display: flex;
	justify-content: center;
	align-items: center;

	& > * {
		height: 100%;
		width: 100%;
	}

	@media (max-width: ${p => p.theme.responsive.media.sm}){
		font-size: 0.75em;
	}

	// RTL
	${p => p.theme.direction === 'ltr' ? css`
		right:1.5em;top:50%;
		translate: 0 -50%;
	` : css`
		left:1.5em;top:50%;
		translate: 0 -50%;
	`}

`;


// types

type MessageProps = {
	messageData: Message<StandardMessageType>,
	onContinue: () => void,
};

export default StandardMessage;