import { GroupProps } from '@react-three/fiber';
import { useAtomValue } from 'jotai';
import { LazyExoticComponent, Suspense, lazy, useEffect, useState } from 'react';
import { scenarioDataAtom } from '../../../../atoms/content';
import { AudioLibrary } from '../../../audio/Audio';
import { SceneData } from '../../../../atoms/scene';

const GenericOffice = lazy(() => import('./generic_office/Secondfloor_office'));
const UniveOffice = lazy(() => import('./unive_reception/UniveReception'));
const Tumi_store = lazy(() => import('./tumi_store/Tumi_store'));
const Jet_street = lazy(() => import('./jet_street/Jet_street'));
const Flagship_store_clothing = lazy(() => import('./flagship_store/Flagship_store_clothing'));
const Backoffice = lazy(() => import('./retail_backoffice/Flagship_backroom'));

// list of different environments

// id refers to an image on Directus
const environments: {[key: string] : {model: LazyExoticComponent<(props: GroupProps) => JSX.Element>, sound: string}} = {
	'78b3962c-d379-43b4-af71-58e877669530' : {model: GenericOffice, sound: 'background_office'},
	'5c8709ff-be29-4435-af48-3932890de64b' : {model: UniveOffice, sound: ''},
	'6df635e2-d38a-41bb-8af5-254de363aadd' : {model: Tumi_store, sound: ''},
	'b3bca7fc-50e2-4520-b7c8-995a445a7ce7' : {model: Jet_street, sound: 'background_street'},
	'ba165b0f-8d24-4fd2-972f-bbfe8b26689b' : {model: Flagship_store_clothing, sound: ''},
	'fffcd77e-e772-420e-b9f4-0f2f447ce477' : {model: Backoffice, sound: ''},
};

const Environment = ({sceneData} : {sceneData: SceneData} ) => {
	const scenarioData = useAtomValue(scenarioDataAtom);
	const [activatedRooms, setActivatedRooms] = useState<string[]>([]);

	useEffect(() => {
		setActivatedRooms([]);
		if(!scenarioData) return;
		//sets environment chosen in editor if there is no room set locally
		if(!sceneData.room) setActivatedRooms([scenarioData.environment]);
		
		const sound = environments[scenarioData.environment].sound;
		sound && AudioLibrary[sound].load().play();
	}, [scenarioData]);

	useEffect(() => {
		//adds room to activated list
		if(sceneData.room && !activatedRooms.includes(sceneData.room)){
			const env = sceneData.room;
			setActivatedRooms((e) => [...e, env]);
		}
	}, [sceneData]);

	return ( 
		<>
			{scenarioData && activatedRooms.map((room) => {
				const DefaultRoom = environments[scenarioData.environment].model;
				const Room = environments[room].model;

				return (<>
					<DefaultRoom
						position={!sceneData.room ? [0,0,0] : [9999,9999,9999]} 
						visible={!sceneData.room ? true : false} 
					/>
					<Suspense key={`env-${room}`}>
						<Room
							position={sceneData.room === room ? [0,0,0] : [9999,9999,9999]} 
							visible={sceneData.room === room ? true : false} 
						/>
					</Suspense>
				</>
				);
			})}
		</>
	);
};
 
export default Environment;